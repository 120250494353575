<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
      @callparentfolder="sendParentfolder()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-folder-edit</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("admin.managefile") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- content -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontaineradmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                  <!-- <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader> -->
                </v-flex>
                <v-flex lg12 class="ma-4">
                  <!-- <v-layout row wrap class="pl-3 pr-3"> -->
                     <!-- <v-btn @click="setuploademployee()">รายชื่อพนักงาน</v-btn> -->
                     <!-- <v-flex offset-lg10> -->
                    
                    <!-- </v-flex> -->
                  <!-- </v-layout> -->

                  <!-- แถบค้นหาและปุ่ม (Desktop) -->
                  <v-layout v-if="resolutionScreen >= 500" row wrap class="pl-3 pr-3">
                    <!-- <v-flex lg2 xs12 :color="color.theme">
                      <v-select
                        :color="color.theme"
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex>  -->
                    <v-flex lg2 >
                      <v-text-field
                        class="pl-1"
                        :label="$t('admin.search')"
                        v-model="search_item"
                        clearable
                        outlined
                        dense
                        :color="color.theme"
                        append-icon="search"
                        @click:clear="loadfolder()"
                      ></v-text-field>                      
                    </v-flex>
                    <v-flex class='pl-1' >
                      <v-btn 
                        :color="color.theme" 
                        :dark="color.darkTheme" 
                        @click="fn_search_fileandfolder()"
                        >{{$t('admin.search')}}</v-btn>
                      <v-btn 
                          class='ml-1'
                          color="green" 
                          dark  
                          @click="setuploadexcel()"
                        >{{$t('admin.upload_permission')}}</v-btn>
                       <v-btn 
                      @mouseover="show_multipledownload = true"
                      @mouseleave="show_multipledownload = false"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      v-if="statusmutipledownload === false"
                      class='ml-1' 
                      rounded
                      fab
                      small 
                      @click="statusmutipledownload = true,show_mutipledownload = false"
                      ><v-icon>mdi-download-multiple</v-icon>
                      <!-- {{$t('adminrightclick.downloadall')}} -->
                      <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-50px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-60px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_download = true"
                        @mouseleave="show_download = false"
                        v-if="statusmutipledownload === true"
                        fab 
                        small 
                        color="primary" 
                        class="ml-1" 
                        @click="multipledownload()">
                          <v-icon>mdi-download-multiple</v-icon>
                          <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-40px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-45px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                      </v-btn>
                      
                       <v-btn
                          @mouseover="show_clear = true"
                          @mouseleave="show_clear = false"
                          v-if="statusmutipledownload === true"
                          fab
                          small
                          color="error"
                          class="ml-1"
                          @click="statusmutipledownload = false,clearmultipledownload()"
                        >
                          <v-icon>clear</v-icon>
                          <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                       </v-btn>
                    </v-flex>
                    <!-- <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down">
                     
                    </v-flex> -->
                    <!-- <v-flex lg2 xl2 offset-lg1 >  -->
                      <!-- <div class="text-right mr-1"> -->
                            <!-- <v-btn @click="opensettypeupload = true" outlined  :color="color.theme" :dark="color.darkTheme"> -->
                              <!-- <v-icon class="mr-2">mdi-upload-lock</v-icon> -->
                              <!-- {{$t('admin.settypeupload')}} -->
                            <!-- </v-btn> -->
                          <!-- </div> -->
                    <!-- </v-flex> -->
                    <!-- <v-flex lg2 >
                      
                    </v-flex> -->
                    <v-flex lg2 class="mr-3">
                      <!-- <v-menu transition="slide-y-transition" close-on-click offset-y > -->
                        <!-- <template v-slot:activator="{ on }"> -->
                          <div class="text-center">
                            
                            <v-btn @click.stop="openfolder()" light depressed block :color="color.theme" :dark="color.darkTheme">
                              <v-icon class="mr-2">mdi-cloud-upload</v-icon>
                              {{ $t("uploadfolder.name") }}
                            </v-btn>
                          </div>
                        <!-- </template> -->
                        <!-- <v-list nav> -->
                          <!-- <v-list-item @click.stop="openfile()">
                            <v-list-item-avatar>
                              <v-icon size="30" color="blue-grey darken-4">mdi-file-upload</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title style="color: #263238">{{ $t("uploadfile.name") }}</v-list-item-title>
                          </v-list-item> -->
                          <!-- <v-list-item @click.stop="openfolder()">
                            <v-list-item-avatar>
                              <v-icon size="30" color="blue-grey darken-4">mdi-folder-upload</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title style="color: #263238">{{ $t("uploadfolder.name") }}</v-list-item-title>
                          </v-list-item>
                        </v-list> -->
                      <!-- </v-menu> -->
                    </v-flex>
                  </v-layout>

                  <!-- แถบค้นหาและปุ่ม (Phone) -->
                  <v-layout v-else justify-center row wrap class="pl-3 pr-3 mb-6">
                    <v-flex sm10 xs8>
                      <v-text-field
                        class="pl-1"
                        :label="$t('admin.search')"
                        v-model="search_item"
                        clearable
                        outlined
                        dense
                        :color="color.theme"
                        append-icon="search"
                        @click:clear="loadfolder()"
                      ></v-text-field>                      
                    </v-flex>
                    <v-flex sm2 xs3 class="pl-2">
                      <v-btn 
                        :color="color.theme" 
                        :dark="color.darkTheme" 
                        @click="fn_search_fileandfolder()"
                      >
                        {{$t('admin.search')}}
                      </v-btn>
                    </v-flex>
                    <v-flex sm6 xs6>
                      <v-btn
                        color="green"
                        dark
                        @click="setuploadexcel()"
                      >
                        {{$t('admin.upload_permission')}}
                      </v-btn>
                    </v-flex>
                    <v-flex sm6 xs6>
                      <div class="ml-3 text-center">
                        <v-btn @click.stop="openfolder()" light depressed block :color="color.theme" :dark="color.darkTheme">
                          <v-icon class="mr-2">mdi-cloud-upload</v-icon>
                          {{ $t("uploadfolder.name") }}
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout v-if="resolutionScreen >= 500 ">
                    <v-flex xs4 lg4>
                    </v-flex>
                  
                  </v-layout> -->
                  <!-- <v-layout row wrap class="pl-3 pr-3" v-else>
                    
                    -->
                  <!-- </v-layout> -->
                  <!-- หน้าจอxs -->

                  <!-- <br /> -->

                  <div v-if="resolutionScreen < 500">
                    <div v-if="loaddataprogress === true">
                      <v-flex lg12 class="ma-2 ml-4 mr-4">
                        <!-- <div class="text-center">
                          <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                        </div> -->
                        <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader>
                      </v-flex>
                    </div> 
                    <div v-else>
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length !== 0">
                      <v-list-item
                        v-for="item in rootfile"
                        :key="item.title"
                        @contextmenu="rightclickadmin($event, item)"
                        @click="gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                        style="cursor: pointer;"
                      >
                        <v-list-item-action>
                          <v-badge color="orange" overlap icon="link" v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'">
                            <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          </v-badge>
                          <v-icon v-else large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          <v-chip x-small color="yellow darken-2" dark v-if="item.status_updating !== 'Done'">{{
                            $t("admin.update")
                          }}</v-chip>
                        </v-list-item-action>

                        <v-list-item-content>
                          <!-- มัยแก้ตรงนี้ -->
                          <v-list-item-title>
                          {{ item.file_name }}
                          <v-icon dark small class="pr-1" color="error" v-if="item.status_lock === 'Y'">mdi-lock</v-icon>
                          </v-list-item-title>
                          <!-- /////////// -->
                          <v-list-item-subtitle v-text="calculatesize(item.file_size)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click.stop="rightclickadmin($event, item)">
                            <v-icon>settings</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider inset></v-divider>
                    </v-list>
                    <v-list v-else-if="loaddataprogresstable === false && rootfile.length === 0" class=" elevation-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  </div>
                  <div v-else>
                    <div v-if="loaddataprogresstable === true">
                      <v-flex lg12 class="ma-2 ml-4 mr-4">
                        <!-- <div class="text-center">
                          <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                        </div> -->
                        <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader>
                      </v-flex>
                    </div> 
                     <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        item-key="file_id"
                        :single-select="true" 
                        :show-select="statusmutipledownload"                     
                        class="elevation-0"
                        item-selected
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                        :no-data-text="$t('tablefile.empty')"
                        
                      >
                        <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template  v-slot:[`header.file_lastdtm`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template  v-slot:[`header.file_size`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template  v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:item="props">
                          <tr
                            style="cursor: pointer"
                            @contextmenu="rightclickadmin($event, props.item)"
                            @dblclick="
                              gotodirectory(props.item.file_id, props.item.file_type, props.item.file_department, props.item)
                            "
                          >
                            <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                          </td>-->
                            <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>

                              <!-- <v-badge
                             color="red"
                            overlap
                            icon="mdi-share-variant "
                            v-if="props.item.file_status_share === 'Y'"
                          >
                            <v-icon
                              large
                              :color="props.item.file_icon[1]"
                            >{{ props.item.file_icon[0] }}</v-icon>
                            </v-badge>-->
                              <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              <v-chip x-small color="yellow darken-2" dark v-if="props.item.status_updating !== 'Done'">{{
                                $t("admin.update")
                              }}</v-chip>
                            </td>
                            <td width="33%" v-if="(props.item.data_type === 'compliance_folder' || props.item.data_type === 'business_folder') && $t('default') === 'th'">
                             <!-- <div > -->
                               {{ props.item.file_name | subStr}}
                               <!-- </div>  -->
                            </td>
                            <td  width="33%" v-else-if="(props.item.data_type === 'compliance_folder' || props.item.data_type === 'business_folder') && $t('default') === 'en'">
                              <!-- <div > -->
                                {{ props.item.file_name_eng | subStr}}
                                <!-- </div> -->
                            </td>
                            <td  width="33%" v-else-if="(props.item.data_type === 'nomal_folder' || props.item.data_type !== 'file')">
                              <!-- มัยเพิ่ม -->
                              <!-- <v-icon color="error" small>lock</v-icon> -->
                              <v-tooltip top v-if="props.item.status_lock === 'Y'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    lock
                                  </v-icon>
                                </template>
                                <span>{{ props.item.file_type === 'folder' ? 'โฟลเดอร์ถูกล็อค': 'ไฟล์ถูกล็อค' }}</span>
                              </v-tooltip>
                              <!-- /////////// -->
                              <!-- <div > -->
                                {{ props.item.file_name | subStr}}
                                <!-- </div> -->
                            </td>
                            <td  width="33%" v-else-if="(props.item.data_type === 'file')">
                              <!-- มัยเพิ่ม -->
                              <!-- <v-icon color="error" small>lock</v-icon> -->
                              <v-tooltip top v-if="props.item.status_lock === 'Y'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    lock
                                  </v-icon>
                                </template>
                                <span>{{ props.item.file_type === 'folder' ? 'โฟลเดอร์ถูกล็อค': 'ไฟล์ถูกล็อค' }}</span>
                              </v-tooltip>
                              <!-- ///////////// -->
                              <!-- <div > -->
                                {{ props.item.file_name | subStr}}
                                <!-- </div> -->
                            </td>


                            <td  width="15%" v-if="$t('default') === 'th'">
                              <!-- <div > -->
                                {{ props.item.file_owner_th }}
                                <!-- </div> -->
                            </td>
                            <td  width="15%" v-else>
                              <!-- <div > -->
                                {{ props.item.file_owner_eng }}
                                <!-- </div> -->
                              <!-- <v-icon
                              small
                              color="orange"
                              v-if="props.item.file_status_share === 'Y'"
                            >group_add</v-icon> -->
                            </td>
                            <td width="18%" class="text-center">{{ formatdatetime(props.item.file_lastdtm) }}</td>
                            <td width="15%" class="text-center">{{ calculatesize(props.item.file_size) }}</td>
                            <!-- <td width="10%" class="text-center" ></td> -->
                            <td width="12%" class="text-center">
                              <v-btn class="elevation-0" fab small @click="rightclickadmin($event, props.item)">
                                <v-icon>settings</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                
                  <!-- <infinite-loading @infinite="loadfile"></infinite-loading> -->
                  <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="countdata > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="changepage()"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- <scroll-loader class="pa-0" :loader-method="loadfolder" :loader-disable="disablescrolling">
              </scroll-loader> 
               <div class="text-center" v-if="loaddataprogresstable === true">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>    -->
            </v-container>
            <adminrightclick
              :parentfolder="parentfolder"
              :show="showadminMenu"
              :AreaX="x"
              :AreaY="y"
              @closeinboxmenu="showadminMenu = false"
              :file="currentfile"
              @downloadfile="downloadfile(currentinboxfile)"
              @loaddata="loadfolder(), (showadminMenu = false)"
              @unlocknotsuccess="(showadminMenu = false)"
              @downloadpermission="download(currentfile)"
            ></adminrightclick>
            <setpermissionfile
              :show="opensetPermissionFile"
              :filedata="currentfile"
              @closesetPermissionFile="(opensetPermissionFile = false), loadfolder()"
              @closenoreload="opensetPermissionFile = false"
              @opensetPermissionFile="opensetPermissionFile = true"
              @closeDrag="$emit('closeDrag')"
            ></setpermissionfile>
            <uploadfiles
              :show="openUploadfiles"
              :parentfolder="parentfolder"
              @close="closeDialog(), (openUploadfiles = false), $emit('callstorage'), $emit('openDrag')"
              @openuploadfile="openUploadfiles = true"
              @closeDrag="$emit('closeDrag')"
            ></uploadfiles>
            <uploadfolder
              :show="openUploadfolder"
              :parentfolder="parentfolder"
              @closedialog="(openUploadfolder = false)"
              @close="(openUploadfolder = false)"
              @closeandreload="(openUploadfolder = false), loadstorage(), loadfolder()"
              @openuploadfolder="openUploadfolder = true"
              @closeDrag="$emit('closeDrag')"
              @openDrag="$emit('openDrag')"
            ></uploadfolder> 
            <settypeupload
            :show="opensettypeupload"
            @close="opensettypeupload = false"
            @closeDrag="$emit('closeDrag')"
            @openDrag="$emit('openDrag')"
            >       
            </settypeupload>
            <uploadexcel
              :show="openuploadfile"
              @close="openuploadfile = false"
              @closeDrag="$emit('closeDrag')"
            ></uploadexcel>
            <dialogsnackbarmultidownload
            :show="opensnackbarmultidownload"
            @closedialog="opensnackbarmultidownload = false"
            :percent="percent"
            :date="datetime"
            :btsdownload="btsdownload"
            :namedowload="namedowload"
            ></dialogsnackbarmultidownload>
            </v-card-text
        ></v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
// import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import DialogSettypeupload from '../components/optional/dialog-settypeupload.vue';
import format from "date-fns/format";
const adminrightclick = () => import("../components/contextmenu/adminrightclick");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles2");
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const setpermissionfile = () => import("../components/optional/dialog-setpermissionfile");
const settypeupload = () => import("../components/optional/dialog-settypeupload");
const uploadexcel = () => import("../components/optional/dialog-uploadexcel");
const dialogsnackbarmultidownload = () => import("../components/optional/dialog-snackbarmultidowmload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_admin_toolbar,
    setpermissionfile,
    adminrightclick,
    uploadfiles,
    uploadfolder,
    settypeupload,
    DialogSettypeupload,
    uploadexcel,
    dialogsnackbarmultidownload
  },
  data: function() {
    return {
      show_multipledownload:false,
      show_download:false,
      show_clear:false,
      datenow: format(new Date(), "dd_MM_yyyy"), 
      namedowload:"",
      snackbardowload:false,
      btsdownload:false,
      datetime:"",
      percent:0,
      opensnackbarmultidownload:false,
      list_folder:[],
      filemultipledownload:[],
      statusmutipledownload: false,
      openuploadfile:false,
      opensettypeupload:false,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      countfolder: "",
      countfile: "",
      countdata:0,
      disablescrolling:true,
      openUploadfiles: false,
      openUploadfolder: false,
      loaddataprogresstable: true,
      opensetPermissionFile: false,
      showadminMenu: false,
      rootfile: [],
      currentfile: [],
      search_item: "",
      countfile: "",
      countfolder: "",
      parentfolder: "",
      loaddataprogress: true,
      size: 50,
      page: 1,
      x: 0,
      y: 0,
      listsize: [20, 50, 100],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          value: "file_name",
          width: "33%",
           align: "left",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          value: "file_owner",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "18%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "center",
          sortable: false,
        },
      ],
      parent_search:"",
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        console.log('this.countdata',this.countdata);
        let l = this.countdata;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 50) return string.substring(0, 50) + "...";
        else return string;
      }
    },
  },
  methods: {
    setuploadexcel(){
      this.openuploadfile = true;
    },
    closeDialog() {
      this.openUploadfolder = false;
      this.openUploadfiles = false;
      this.loadfolder();
    },
    openfile() {
      //console.log("rot", this.$router.currentRoute.path);
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfile.donotshare")
      // });
      // } else {
      (this.openUploadfiles = true), this.sendParentfolder();
      // }
    },
    openfolder() {
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfolder.donotshare")
      // });
      //} else {
      (this.openUploadfolder = true), this.sendParentfolder();
      // }
    },
    sendParentfolder() {
      // if(this.$route.params.id === undefined){
      //   this.parentfolder = ''
      // }else{
      //   this.parentfolder = this.$route.params.id
      // }

      this.parentfolder = this.dataAccountActive["business_info"]["my_folder_id"];
      console.log("parentfolder", this.parentfolder);
    },
    opensetpermission(file) {
      this.currentfile = file;
      this.opensetPermissionFile = true;
      console.log(this.opensetPermissionFile);
    },
    rightclickadmin(e, filename) {
      // console.log(e);
      e.preventDefault();
      //this.sendParentfolder();
      this.showadminMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.currentfile = filename;
        console.log("currenfile",this.currentfile);
        // this.opensetPermissionFile = true;
        console.log(this.opensetPermissionFile);

        //   this.showadminMenu = true;
        setTimeout(() => {
          this.showadminMenu = true;
        }, 300);
      });
    },
    download_(){
      console.log("llllllll");
    },
    download(currentfile){
      console.log("download",currentfile);
        let payload = {
          business_id: this.dataAccountActive.business_info.business_id,
          type_download:"N",
          list_folder:[currentfile.file_id],
      };
      console.log("payload",payload);
      this.opensnackbarmultidownload = true;
      this.snackbardowload = true;
      this.namedowload = currentfile.file_name;
      this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_permission_biz", 
            payload,
            {
              onDownloadProgress: progressEvent => {
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                 console.log("progresspercent",progresspercent);
                // if (progresspercent >= 95) {
                //   this.percentCompleted = 95;
                // } else {
                this.percentCompleted = progresspercent;
                // }
                this.percent = this.percentCompleted;
              },
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
        .then((response) => {
          console.log("folder0000", response);
          // console.log("count",response.data.folder.length);
          if (response.statusText === "OK") {
            this.loaddataprogresstable = false;
            if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
              this.opensnackbarmultidownload = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              Toast.fire({ icon: "error", title: res_error.errorMessage });
            } else {
              this.percentCompleted = 100;
              this.btsdownload = false;

              let headers = response.headers;
              let blob = new Blob([response.data],{type:headers['content-type']});
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = currentfile.file_name;
              link.click();

              // const blob = new Blob([response.data]);
              // const content = response.headers["content-type"];
              // saveAs(blob, this.datetime + ".zip");

              setTimeout(() => {
                this.opensnackbarmultidownload = false;
              }, 2500);
            }
          
          }
          
            // this.clearmultipledownload();
          })
        .catch((error) => {
          this.btsdownload = false;
          this.$store.dispatch("show_loading", false);
          Toast.fire({
            icon: "error",
            title: this.$t("myinboxPage.msg.apierror"),
          });
          console.log(error);
        });
        this.$emit("closecurrentonly");
        this.$emit("clearparentvalue");
      
    },
     clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    async multipledownload() {
      console.log("filemultipledownload",this.filemultipledownload);
      this.percentCompleted = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);

        console.log("this.datetime",this.datetime);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile")
        });
      } else {
        // this.$store.dispatch("show_loading", true);
         let filedata = [];
         for (let i = 0; i < this.filemultipledownload.length; i++) {
             filedata.push(this.filemultipledownload[i]["file_id"])
         }
         console.log("multi", filedata);

        let payload = {
          business_id: this.dataAccountActive.business_info.business_id,
          type_download:"N",
          list_folder:filedata,
        };
        console.log("filemultipledownload",payload);

      this.opensnackbarmultidownload = true;
      this.snackbardowload = true;
      this.namedowload =  this.datetime;
      await this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_permission_biz", 
            payload,
            {
              onDownloadProgress: progressEvent => {
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                 console.log("progresspercent",progresspercent);
                // if (progresspercent >= 95) {
                //   this.percentCompleted = 95;
                // } else {
                this.percentCompleted = progresspercent;
                // }
                this.percent = this.percentCompleted;
              },
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
        .then((response) => {
          console.log("folder0000", response);
          // console.log("count",response.data.folder.length);
          if (response.statusText === "OK") {
            this.loaddataprogresstable = false;
            if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
              this.opensnackbarmultidownload = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              Toast.fire({ icon: "error", title: res_error.errorMessage });
            } else {
              this.percentCompleted = 100;
              this.btsdownload = false;

              let headers = response.headers;
              let blob = new Blob([response.data],{type:headers['content-type']});
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = this.datetime;
              link.click();

              // const blob = new Blob([response.data]);
              // const content = response.headers["content-type"];
              // saveAs(blob, this.datetime + ".zip");

              setTimeout(() => {
                this.opensnackbarmultidownload = false;
              }, 2500);
            }

          }
          
            // this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
        this.$emit("closecurrentonly");
        this.$emit("clearparentvalue");
      
      //   this.snackbardowload = true;
      //   // let auth = await gbfGenerate.generateToken();
      //   this.axios
      //     .post(
      //       process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
      //         "/api/download_permission_biz",
      //       payload,
      //       {
      //         onDownloadProgress: progressEvent => {
      //           let progresspercent = parseInt(
      //             Math.round((progressEvent.loaded / progressEvent.total) * 100)
      //           );
      //           // console.log(progresspercent);
      //           if (progresspercent >= 95) {
      //             this.percentCompleted = 95;
      //           } else {
      //             this.percentCompleted = progresspercent;
      //           }
      //         },
      //         withCredentials: false,
      //         responseType: "arraybuffer",
      //         headers: { Authorization: auth.code },
      //       }
      //     )
      //     .then(response => {
      //       this.statusmutipledownload = false;
      //       console.log(response);
      //       if (response.statusText === "OK") {
      //         this.percentCompleted = 100;
      //       }
      //       //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //       //     const link = document.createElement("a");
      //       //     link.href = url;
      //       //     link.setAttribute("download",datetime+".zip");
      //       //     document.body.appendChild(link);
      //       //     link.click();
      //       const blob = new Blob([response.data]);
      //       const content = response.headers["content-type"];
      //       saveAs(blob, this.datetime + ".zip");
      //       this.clearmultipledownload();
      //     })
      //     .catch(error => {
      //       Toast.fire({
      //         icon: "error",
      //         title: this.$t("myinboxPage.msg.apierror")
      //       });
      //       console.log(error);
      //     });
        }
    },
    async loadfolder() {
      //console.log("pageeeeeeeeeee",this.page,this.size);
      this.loaddataprogresstable = true;
      this.disablescrolling = true
      // let cal_limit = this.size;
      // let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_limit,cal_offset,this.size,this.page);
      // if(cal_offset == 0){
      //    this.rootfile = []
      //    this.buff = []
      // }
      this.rootfile = []
          this.buff = []
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      console.log(cal_limit,cal_offset);
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.dataAccountActive["business_info"]["my_folder_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      console.log("folderid", payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder_to_setting", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("folder", response.data.status);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {           
            this.loaddataprogresstable = false;
            //console.log(response);
            for(let i = 0 ; i< response.data.data.length;i++){
             let datafolder = {};
             let typefile = response.data.data[i]["data_name_th"].split(".");
             datafolder["account_id"] = response.data.data[i]["account_id"];
             datafolder["user_id"] = response.data.data[i]["user_id"];
             datafolder["file_size"] = response.data.data[i]["size"];
             datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
             datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
             datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
             datafolder["permission_role_setting"] = response.data.data[i]["permission_role_setting"];
             datafolder["status_updating"] = response.data.data[i]["status_updating"];
             datafolder["data_type"] = response.data.data[i]["data_type"];
             datafolder["file_id"] = response.data.data[i]["id"];
            datafolder["file_name"] = response.data.data[i]["data_name_th"];
              datafolder["file_name_eng"] = response.data.data[i]["data_name_eng"];
             datafolder["file_department"] = "";
             datafolder["business_id"] = response.data.data[i]["business_id"];
             datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
             datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
             datafolder["file_owner_th"] = response.data.data[i]["name_th"];
             datafolder["priority"] = response.data.data[i]["priority"];
             datafolder["file_type"] = this.fn_check_type(response.data.data[i]["data_type"]);
             datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1],response.data.data[i]["data_type"]);
             datafolder["status_lock"] = response.data.data[i]["status_lock"]; // เพิ่มตรงนี้
             this.rootfile.push(datafolder);
            }
            this.parent_search = response.data.your_folder[0]["id"]
            this.countdata = response.data.total;
            this.totaldata = {
              totalall: response.data.total, totalfile: response.data.total_file, totalfolder: response.data.total_folder
            }
            //console.log("--------------------",this.rootfile.length,this.totaldata['totalall']);
            this.count_file = response.data.total_file;
            this.count_folder = response.data.total_folder;
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
             this.disablescrolling = this.totaldata['totalall'] <= this.rootfile.length
            // เรียกใช้ creator_name
            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === '' || this.rootfile[y].file_owner_th === undefined || this.rootfile[y].file_owner_eng === undefined){
                this.get_name_owner(this.rootfile[y])
              }
            }
          } else {
            this.disablescrolling = true
            // this.loaddataprogress = false;
            // this.loaddataprogresstable = false;
          
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          this.disablescrolling = true
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
   async loadfolder_child(){
      this.loaddataprogresstable = true;
      this.disablescrolling = true
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      console.log(cal_limit,cal_offset,this.size,this.page);
      if(cal_offset == 0){
         this.rootfile = []
         this.buff = []
      }
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.dataAccountActive["business_info"]["my_folder_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      console.log("folderid", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder_to_setting", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("folder", response.data.status);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {           
            this.loaddataprogresstable = false;
            //console.log(response);
            for(let i = 0 ; i< response.data.data.length;i++){
             let datafolder = {};
             let typefile = response.data.data[i]["data_name_th"].split(".");
             datafolder["account_id"] = response.data.data[i]["account_id"];
             datafolder["user_id"] = response.data.data[i]["user_id"];
             datafolder["file_size"] = response.data.data[i]["size"];
             datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
             datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
             datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
             datafolder["permission_role_setting"] = response.data.data[i]["permission_role_setting"];
             datafolder["status_updating"] = response.data.data[i]["status_updating"];
             datafolder["data_type"] = response.data.data[i]["data_type"];
             datafolder["file_id"] = response.data.data[i]["id"];
            datafolder["file_name"] = response.data.data[i]["data_name_th"];
              datafolder["file_name_eng"] = response.data.data[i]["data_name_eng"];
             datafolder["file_department"] = "";
             datafolder["business_id"] = response.data.data[i]["business_id"];
             datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
             datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
             datafolder["file_owner_th"] = response.data.data[i]["name_th"];
             datafolder["priority"] = response.data.data[i]["priority"];
             datafolder["file_type"] = this.fn_check_type(response.data.data[i]["data_type"]);
             datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1],response.data.data[i]["data_type"]);
             datafolder["status_lock"] = response.data.data[i]["status_lock"]; // เพิ่มตรงนี้
             this.rootfile.push(datafolder);
            }
            this.parent_search = response.data.your_folder[0]["id"]
            this.countdata = response.data.total;
            this.totaldata = {
              totalall: response.data.total, totalfile: response.data.total_file, totalfolder: response.data.total_folder
            }
            //console.log("--------------------",this.rootfile.length,this.totaldata['totalall']);
            this.count_file = response.data.total_file;
            this.count_folder = response.data.total_folder;
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
             this.disablescrolling = this.totaldata['totalall'] <= this.rootfile.length
          } else {
            this.disablescrolling = true
            // this.loaddataprogress = false;
            // this.loaddataprogresstable = false;
          
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          this.disablescrolling = true
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    fn_check_type(parameter){
      let type = ""
      if(parameter === "normal_folder"){
        type = "folder"
      }else if(parameter === "dept_folder"){
        type = "folder"
      }else if(parameter === "business_folder"){
        type = "folder"
      }else if(parameter === "compliance_folder"){
        type = "folder"
      }else{
        type = "file"
      }
      return type
    },
    open_settype_uploadfile(){
      this.opensettypeupload = true;
    },
    // โหลดข้อมูล ไฟล์
   async loadfiles(folder, status) {
      console.log(folder);

      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder,
        status_file: status,
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file", payload,{
            headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("file", response);
          // console.log("countfile", response.data.data.length);

          if (response.data.status === "OK") {
            console.log("apifile", response);

            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["data_name_th"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["data_name_th"];
              datafolder["file_name_eng"] = response.data.data[i]["data_name_eng"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["file_linkshare"] = response.data.data[i]["link"];
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["file_date"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_status_share"] = response.data.data[i]["status_share"];
              datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
              datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
              datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
              datafolder["permission_role_setting"] = response.data.data[i]["permission_role_setting"];
              datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1]);
              datafolder["status_updating"] = response.data.data[i]["status_updating"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loaddataprogress = false;
            this.loaddataprogresstable = false;
            this.countfile = response.data.data.length;
          } else {
            this.loaddataprogress = false;
            this.loaddataprogresstable = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
      this.$store.dispatch("check_color", localStorage.getItem("color")).then((res) => {});
    },
    changepage() {
      // this.loaddataprogress = true;
      // setTimeout(() => {
      //   this.loaddataprogress = false;
      // }, 1000);
      if(this.search_item !== "" && this.search_item !== null){
       console.log(this.countdata);
      }else{
        this.loadfolder();
      }
      
    },
    formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    seticon(parameter,item) {
      // console.log(parameter);
      // let parameter_ = parameter.toLowerCase()
      let dataicon;
      if (item === "file") {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (parameter === "csv") {
          dataicon = ["mdi-file-table", "green"];
        } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if(parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4"||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv")
        {
          dataicon = ["mdi-file-video", "purple"];
        } else if(parameter === "shortcut"){
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      } else if (item === "normal_folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else if (item === "dept_folder") {
        dataicon = ["mdi-folder-heart", "green"];
      } else if (item === "business_folder") {
        dataicon = ["mdi-folder-network", "blue darken-4"];
      } else if (item === "compliance_folder") {
        dataicon = ["mdi-book-open-page-variant", "pink lighten-2"];
      }
      return dataicon;
    },
    gotodirectory(folderid, type, departmentid, file) {
      console.log("folderid", folderid,file);
      
      if (type === "folder" && file.data_type !== "business_folder") {
        console.log("folderiddepartmentid", departmentid);
        this.$store.dispatch("department_access_id", departmentid);
        this.$router.replace({ path: "/onebox-system-admin-managedirectoryfile/" + folderid });
        // this.$router.replace({ path: "/system-admin-managedirectoryfile/" + folderid });
        //this.loadfolder();
      } else {
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },
  async  fn_search_fileandfolder(){
      console.log(this.search_item);
      if(this.search_item !== "" && this.search_item !== null){
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.parent_search,
        name: this.search_item,
      };
      console.log("folderid", payload);
      let auth = await gbfGenerate.generateToken();
    await  this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/search_file_folder_in_setting", payload,{ headers: {Authorization: auth.code} })
        .then((response) => {
          console.log("folder", response.data.status);
          // console.log("count",response.data.folder.length);
          //this.loaddataprogresstable = false;
          if (response.data.status === "OK") { 
            console.log("folder", response);
            for(let i = 0 ; i< response.data.data.length;i++){
             let datafolder = {};
             let typefile = response.data.data[i]["data_name_th"].split(".");
             datafolder["account_id"] = response.data.data[i]["account_id"];
             datafolder["user_id"] = response.data.data[i]["user_id"];
             datafolder["file_size"] = response.data.data[i]["size"];
             datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
             datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
             datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
             datafolder["permission_role_setting"] = response.data.data[i]["permission_role_setting"];
             datafolder["status_updating"] = response.data.data[i]["status_updating"];
             datafolder["data_type"] = response.data.data[i]["data_type"];
             datafolder["file_id"] = response.data.data[i]["id"];
             datafolder["file_name"] = response.data.data[i]["data_name_th"];
             datafolder["file_name_eng"] = response.data.data[i]["data_name_eng"];
             datafolder["file_department"] = "";
             datafolder["business_id"] = response.data.data[i]["business_id"];
             datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
             datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
             datafolder["file_owner_th"] = response.data.data[i]["name_th"];
             datafolder["priority"] = response.data.data[i]["priority"];
             datafolder["file_type"] = this.fn_check_type(response.data.data[i]["data_type"]);
             datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1],response.data.data[i]["data_type"]);
             this.rootfile.push(datafolder);
            }
             this.countdata = response.data.total;
            this.disablescrolling = this.countdata <= this.rootfile.length
            // เรียกใช้ creator_name
            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === '' || this.rootfile[y].file_owner_th === undefined || this.rootfile[y].file_owner_eng === undefined){
                this.get_name_owner(this.rootfile[y])
              }
            }
          }else{
            this.disablescrolling = true
          }
          }).catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          this.disablescrolling = true
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
      }else{
        
      }          
    },
    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
  },
  mounted() {
    //document.getElementById("changeColor").style.color = this.color;
    this.loaddataprogress = false;
    this.loaddataprogresstable = false;
    this.disablescrolling = false;
    this.$store.dispatch("department_access_id", "");
    this.loadstorage();
    this.loadfolder();
    // this.loadcolor();
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
#thiscontaineradmin {
  height: calc(101vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
