<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->

      <v-card height="750px">
        <v-card-title>ตั้งค่าไฟล์ที่ไม่อนุญาติให้อัปโหลด</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-flex lg3 md3 xs12>
          <v-text-field
            class="pl-1"
            :label="$t('admin.search')"
            v-model="search_item"
            clearable
            outlined
            dense
            :color="color.theme"
            append-icon="search"
            
          ></v-text-field>
          </v-flex>
          <v-tabs v-model="tab">
            <v-tab v-for="item in listTypeFile" :key="item.tab">
              {{ item.tab_type }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in listTypeFile" :key="item.tab"
              ><div v-if="loaddataprogress === true">
                <v-flex lg12 class="ma-2 ml-4 mr-4">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
              </div>
              <div v-else>
                <!-- <div class="text-right"><v-btn>new item</v-btn></div> -->
                <v-list-item v-for="(item_, i) in item.extension_file" :key="i">
                  <v-list-item-content>
                    <v-list-item-title>{{ item_.extension_name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch v-model="item_.status" inset :color="color.theme"></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <!-- <v-list>
          <v-list-item v-for="(item,i)  in listTypeFile" :key="i" > -->
          <!-- <v-list-item-title>{{item.text}} -->
          <!-- <v-checkbox
            dense
           v-model="selected"
           :label="item.text"
           :value="item.key"
         ></v-checkbox> -->
          <!-- <template v-slot:activator> -->
          <!-- <v-list-item class="pa-0">
                  <v-list-item-action>
              <v-icon large>{{ item.icon }}</v-icon>
            </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-row>
                      <v-col v-for="(child, n) in item.children" :key="n" > -->
          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
          <!-- <v-checkbox
                            :color="color.theme"
                            class="pl-12 mt-0 pt-0 "
                            hide-details
                            dense
                            v-model="child.value"
                            :label="child.text"                  
                         ></v-checkbox>
                          
                        </v-col>
                        </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
               -->
          <!-- </template> -->
          <!-- </v-list-item-title> -->
          <!-- <v-list-item v-for="(child, i) in item.children" :key="i" dense>
             <v-list-item-content dense>
                 <v-list-item-title dense>
                 <v-checkbox
                 :color="color.theme"
                    class="pl-12 mt-0 pt-0 "
                    hide-details
                    dense
                    v-model="child.value"
                    :label="child.text"
                   
                ></v-checkbox>
                 </v-list-item-title>
             </v-list-item-content>
         </v-list-item> -->
          <!-- <v-list-item-action><v-icon large>{{item.icon}}</v-icon></v-list-item-action> -->
          <!-- </v-list-item>
         </v-list>  -->
          <!-- <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                  <v-text-field :color="color.theme"  dense  label="อื่นๆ" v-model="typefile" ></v-text-field>
              </v-list-item-content>
          </v-list-item> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close'), $emit('openDrag')">{{ $t("changeName.close") }}</v-btn>
          <v-btn :loading="settypefileprogress" :color="color.theme" :dark="color.darkTheme" @click="fn_set_typefile()"
            >ตั้งค่า</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
   
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      search_item:"",
      selected: true,
      listTypeFile: [],
      tab: null,
      loaddataprogress: false,
      settypefileprogress: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.fn_show_typefile();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closechangename");
        }
      },
    },
  },
  methods: {
    async fn_show_typefile() {
      this.loaddataprogress = true;
      this.listTypeFile = [];
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/select_all_file_extension", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            console.log("res", response);
            for (let i = 0; i < response.data.result.length; i++) {
              let data = {};
              data["tab_type"] = response.data.result[i]["name"];
              data["extension_file"] = this.settoboolean(response.data.result[i]["extension_file"]);
              this.listTypeFile.push(data);
            }
            this.loaddataprogress = false;
          } else {
          }
        });
    },
    settoboolean(parameter) {
      for (let i = 0; i < parameter.length; i++) {
        if (parameter[i].status === "False") {
          parameter[i].status = false;
        } else {
          parameter[i].status = true;
        }
      }
      return parameter;
    },

   async fn_set_typefile() {
      this.settypefileprogress = true;
      let type_set = [];
      for (let i = 0; i < this.listTypeFile.length; i++) {
        for (let j = 0; j < this.listTypeFile[i]["extension_file"].length; j++) {
          if (this.listTypeFile[i]["extension_file"][j]["status"] === true) {
            type_set.push(this.listTypeFile[i]["extension_file"][j]["extension_name"]);
          }
        }
      }
      console.log(type_set);
      var payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        type_excluded: type_set,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/setting_exclude_files_type", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.settypefileprogress = false;
            Toast.fire({
              icon: "success",
              title: "success",
            });

            this.$emit("close");
          } else {
            this.settypefileprogress = false;
            Toast.fire({
              icon: "error",
              title: "error",
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.settypefileprogress = false;
        });
    },
  },
};
</script>
<style>
.tab-body-wrapper {
  height: 100%;
}
</style>

